import st from "./Post.module.css";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from '@mui/icons-material/Close';
import {HorizontalScroll} from "../HorizontalScroll/HorizontalScroll";

const IMAGE_URL = 'http://api.test'

export const Post = ({postData}) => {
    const [showed, setShowed] = useState(false)

    useEffect(() => {
        console.log(postData)
    }, [postData]);

    return <div
        className={st.card}
        onClick={() => setShowed(true)}
        style={{backgroundImage: `url(${IMAGE_URL}${postData.image})`}}
    >
        <div className={st.cardBottom}>
            <div className={st.postName}>
                {postData.name}
            </div>
            <Button
                className={st.postButton}
                color="primary"
                size="small"
                variant="contained"
            >
                Подробнее
            </Button>
        </div>
        <Modal
            open={showed}
            onClose={() => setShowed(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={st.modalBox}>
                <div className={st.modalTop}>
                    <div className={st.topTitle}>{postData.name}</div>
                    <div className={st.topClose}>
                        <IconButton
                            className={st.iconButton}
                            color="error"
                            aria-label="Выйти назад"
                            onClick={() => setTimeout(() => {
                                setShowed(false)
                            }, 450)}
                        >
                            <CloseIcon
                                className={st.icon}
                                sx={{fontSize: 50}}
                            />
                        </IconButton>
                    </div>
                </div>
                <div className={st.modalContent}>
                    <div className={st.postText}>
                        {postData.text}
                    </div>
                    {postData?.images != null && postData.images.length > 0 && (
                        <HorizontalScroll
                            images={postData.images.map(i => `${IMAGE_URL}${i}`)}
                            title={'Фогографии'}
                        />

                    )}
                    {postData?.documents != null && postData.documents.length > 0 && (
                        <HorizontalScroll
                            images={postData.documents.map(i => `${IMAGE_URL}${i}`)}
                            title={'Документы'}
                        />

                    )}
                </div>
            </Box>
        </Modal>
    </div>
}