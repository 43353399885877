import st from './War.module.css'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const STAGE_2 = 2 // Поиск предметов
const STAGE_3 = 3 // Подсказка
const STAGE_4 = 4 // Поиск лишних предметов

const HIDE_TIME = 40000
const HINT_TIME = 3000

export const War = ({ playAudio, stopAudio }) => {
    const navigate = useNavigate();
    const [stage, setStage] = useState(STAGE_2) // Состояние сцены
    const [rule2, setRule2] = useState(false) // Правила исключения
    const [corrects, setCorrects] = useState([4, 7, 10, 12]); // Верные индексы поиска
    const [repletion, setRepletion] = useState([16]); // Верные индексы лишних предметов
    const [showGuide, setShowGuide] = useState(false) // Показать гида
    const [showSearch, setShowSearch] = useState(false) // Показать доску с названиями
    const [availableHint, setAvailableHint] = useState(true) // Доступна ли лупа
    const [hint, setHint] = useState(null) // Подсказка на индексе предмета
    const [timer, setTimer] = useState(0) // Значение таймера


    // Элементы для поиска и исключения с координатами
    const elements = [
        {w: 12, h: 21, t: 15, l: 7},
        {w: 10, h: 46, t: 36, l: 6},
        {w: 10, h: 39, t: 43, l: 14},
        {w: 6, h: 5, t: 16, l: 22},
        {w: 2, h: 5, t: 16, l: 29}, // index 4 important
        {w: 9, h: 5, t: 16, l: 31},
        {w: 6, h: 9, t: 24, l: 25},
        {w: 8, h: 5, t: 26.5, l: 32}, // index 7 important
        {w: 11, h: 28, t: 48, l: 29},
        {w: 10, h: 17, t: 12, l: 45.5},
        {w: 11, h: 18.5, t: 31.4, l: 45.2}, // index 10 important
        {w: 12, h: 11, t: 12, l: 60},
        {w: 5.6, h: 11.4, t: 11.6, l: 73}, // index 12 important
        {w: 25, h: 11, t: 25, l: 57},
        {w: 10, h: 33, t: 12, l: 83},
        {w: 9, h: 13, t: 53, l: 53},
        {w: 6, h: 10, t: 61, l: 47} // index 16 negative important
    ]

    // Заголовки на доске для поиска которые потом нужно скрыть
    const searchItemsCoords = [
        {w: 17, h: 13, t: 32, l: 11, ix: 7},
        {w: 32, h: 16, t: 32, l: 33, ix: 10},
        {w: 24, h: 16, t: 54, l: 14, ix: 12},
        {w: 22, h: 13, t: 54, l: 46, ix: 4},
        // {w: 5.5, h: 11, t: 12, l: 73, ix: 12}
    ]

    // Заголовки на доске для поиска лишнего предмета
    const searchRepletionCoords = [
        {w: 30, h: 14, t: 33, l: 23, ix: null},
        {w: 39, h: 14, t: 53, l: 11, ix: null},
        {w: 13, h: 12, t: 53, l: 59, ix: 16}
    ]

    const itemsToDrop = [
        {ix: 4},
        {ix: 7},
        {ix: 10},
        {ix: 12},
        {ix: 16}
    ]

    // Показать подсказку
    const showHint = () => {
        if (!availableHint)
            return
        setTimer(HIDE_TIME / 1000)
        if (availableHint) {
            let toHint = null
            if (stage === STAGE_2)
                toHint = corrects[Math.floor(Math.random() * corrects.length)]
            else if (stage === STAGE_4)
                toHint = repletion[Math.floor(Math.random() * repletion.length)]
            else return

            setHint(toHint)
            setAvailableHint(false)
            setTimeout(() => {
                setHint(null)
            }, HINT_TIME)
            playAudio('timer', true)
        }
    }

    // Поиск по предметам
    const searchClick = value => {
        if (stage === STAGE_2)
            if (corrects.includes(value)) {
                const ind = corrects.indexOf(value);
                if (ind > -1) {
                    playAudio('item')
                    setCorrects(corrects.filter(item => item !== value))
                }
            } else {
                console.log('incorrect', value)
            }
        else if (stage === STAGE_4)
            if (repletion.includes(value)) {
                playAudio('item')
                stopAudio('timer')
                navigate('/task4')
            } else {
                console.log('incorrect repletion', value)
            }
    }

    // init
    useEffect(() => {
        setShowSearch(true)
        setShowGuide(false)
        setStage(STAGE_2)
        playAudio('background', true)
    }, [])

    useEffect(() => {
        if (corrects.length === 0)
            setStage(STAGE_3)
    }, [corrects])

    useEffect(() => {
        if (timer > 0) {
            if (availableHint)
                setAvailableHint(false)
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        } else {
            stopAudio('timer')
            setAvailableHint(true)
        }
    }, [timer])

    useEffect(() => {
        switch (stage) {
            case STAGE_3:
                setShowSearch(false)
                setShowGuide(true)
                setRule2(true)
                break
            case STAGE_4:
                setShowGuide(false)
                setShowSearch(true)
                setRule2(false)
                break
        }
    }, [stage])

    return <div className={st.wrapper}>

        {elements.map((el, ix) => {
                let toDrop = itemsToDrop.find(el => el.ix === ix)
                let opacity = toDrop === undefined || (corrects.includes(ix) || repletion.includes(ix)) ? 1 : 0
                return <div
                    key={`el-${ix}`}
                    onClick={() => searchClick(ix)}
                    className={`${st.element} ${ix === hint ? st.glow : ''}`}
                    style={{
                        width: `${el.w}%`,
                        height: `${el.h}%`,
                        top: `${el.t}%`,
                        left: `${el.l}%`,
                        opacity: opacity ? 1 : 0,
                        backgroundImage: `url(${process.env.PUBLIC_URL}/game/war/el${ix}.png)`,
                        '--glow-image': `url(${process.env.PUBLIC_URL}/game/war/el${ix}.png)`
                    }}
                ></div>
            }
        )}

        <div className={`${st.rules2} ${rule2 ? st.showRule2 : st.hideRule2}`}>
            <div
                className={st.close}
                onClick={() => setStage(STAGE_4)}
            ></div>
        </div>
        <div className={`${st.guide} ${showGuide ? st.showGuide : st.hideGuide}`}></div>
        <div
            className={`${st.search} ${stage === STAGE_2 ? st.se : st.re} ${showSearch ? st.showSearch : st.hideSearch}`}>
            {stage === STAGE_2 ? (<>
                {searchItemsCoords.map((si, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/war/item${ix}.png)`,
                            width: `${si.w}%`,
                            height: `${si.h}%`,
                            top: `${si.t}%`,
                            left: `${si.l}%`,
                            opacity: `${corrects.includes(si.ix) ? 1 : 0.4}`
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>) : (<>
                {searchRepletionCoords.map((sr, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/war/repletion${ix}.png)`,
                            width: `${sr.w}%`,
                            height: `${sr.h}%`,
                            top: `${sr.t}%`,
                            left: `${sr.l}%`,
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>)}
            <div
                className={st.glass}
                style={{
                    opacity: `${availableHint ? 1 : 0.6}`,
                    cursor: `${availableHint ? 'pointer' : 'default'}`
                }}
                onClick={showHint}
            ></div>
            {!availableHint && (<div className={st.timer}>
                {timer}
            </div>)}
        </div>
    </div>
}