import {Box, IconButton, Modal} from "@mui/material";
import st from "./FullImage.module.css";
import CloseIcon from "@mui/icons-material/Close";


export const FullImage = ({image, open, selectedImage, setOpen}) => {

    return <div style={{position: 'relative'}}>

        {!open ? (
            <img
                src={image}
                draggable="false"
                style={{width: '100%', height: '100%'}}
            />
        ) : (<></>)}

        <Modal
            open={open && image === selectedImage}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={st.modalBox}>
                <div className={st.modalTop}>
                    <div className={st.topClose}>
                        <IconButton
                            className={st.iconButton}
                            color="error"
                            aria-label="Выйти назад"
                            onClick={() => setTimeout(() => {
                                setOpen(false)
                            }, 450)}
                        >
                            <CloseIcon
                                className={st.icon}
                                sx={{fontSize: 50}}
                            />
                        </IconButton>
                    </div>
                </div>
                <div className={st.modalContent} style={{backgroundImage: `url(${image})`}}>

                </div>
            </Box>
        </Modal>
    </div>
}