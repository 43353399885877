import st from './Settings.module.css'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useLocalStorage} from "../../../useLocalStorage";
// import Person_A from '/public/game/settings/person_a.png'

const STATE_START = 0
const STATE_PERSON_A = 1
const STATE_PERSON_B = 2

export const Settings = ({ playAudio }) => {
    const navigate = useNavigate();
    const [state, setState] = useState(STATE_START)
    const [user, setUser] = useLocalStorage("user", null);
    const choosePerson = person => {
        setState(person)
    }

    useEffect(() => {
        playAudio('background', true)
    }, []);

    // Персонажи для выбора
    const personas = [
        {
            id: STATE_PERSON_A,
            image: process.env.PUBLIC_URL + '/game/settings/person_a.png',
            next: STATE_PERSON_B
        },
        {
            id: STATE_PERSON_B,
            image: process.env.PUBLIC_URL + '/game/settings/person_b.png',
            next: STATE_PERSON_A
        }
    ]

    return <div className={st.settingsWrapper}>

        {
            state === STATE_START ? (
                <div className={st.letter}>
                    <div
                        className={st.startButton}
                        onClick={(e) => choosePerson(STATE_PERSON_A)}
                    >
                    </div>
                </div>
            ) : (<></>)
        }

        {
            personas.map(person => {
                return (
                    <div
                        key={person.id}
                        className={st.personChoose}
                        style={{
                            backgroundImage: `url(${person.image})`,
                            visibility: state === person.id ? 'visible' : 'hidden'
                        }}
                    >
                        <div
                            className={st.leftArrow}
                            onClick={() => choosePerson(person.next)}
                        ></div>
                        <div
                            className={st.rightArrow}
                            onClick={() => choosePerson(person.next)}
                        ></div>
                        <div
                            className={st.chooseButton}
                            onClick={async () => {
                                await setUser(person.id)
                                navigate('/task')
                            }}
                        ></div>
                    </div>
                )
            })
        }
    </div>
}