import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import {Wrapper} from "../components/game/Wrapper/Wrapper";
import {Start} from "../components/game/Start/Start";
import {Settings} from "../components/game/Settings/Settings";

import {Task} from "../components/game/Task/Task";
import {Task2} from "../components/game/Task2/Task2";
import {Task3} from "../components/game/Task3/Task3";
import {Task4} from "../components/game/Task4/Task4";
import {Task5} from "../components/game/Task5/Task5";
import {Task6} from "../components/game/Task6/Task6";
import {Task7} from "../components/game/Task7/Task7";

import {Archeology} from "../components/game/Archeology/Archeology";
import {Region} from "../components/game/Region/Region";
import {War} from "../components/game/War/War";
import {Nature} from "../components/game/Nature/Nature";
import {Ussr} from "../components/game/Ussr/Ussr";
import {Culture} from "../components/game/Culture/Culture";
import {Merchants} from "../components/game/Merchants/Merchants";

import {AppWrap} from "../components/app/AppWrap/AppWrap";
import {Main} from "../components/app/Main/Main";
import {Categories} from "../components/app/Categories/Categories";
import {Category} from "../components/app/Category/Category";

export const Router = () => {
    const wrapWithWrapper = (element) => (
        <Wrapper>{element}</Wrapper>
    );
    const wrapAppWrapper = (element) => (
        <AppWrap>{element}</AppWrap>
    );
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={wrapAppWrapper(<Main />)}/>
            <Route path="/categories" element={wrapAppWrapper(<Categories />)}/>
            <Route path="/category/:id" element={<Category />}/>

            <Route path="/start" element={<Start />}/>
            <Route path="/settings" element={wrapWithWrapper(<Settings />)}/>

            <Route path="/task" element={wrapWithWrapper(<Task />)}/>
            <Route path="/task2" element={wrapWithWrapper(<Task2 />)}/>
            <Route path="/task3" element={wrapWithWrapper(<Task3 />)}/>
            <Route path="/task4" element={wrapWithWrapper(<Task4 />)}/>
            <Route path="/task5" element={wrapWithWrapper(<Task5 />)}/>
            <Route path="/task6" element={wrapWithWrapper(<Task6 />)}/>
            <Route path="/task7" element={wrapWithWrapper(<Task7 />)}/>

            <Route path="/archeology" element={wrapWithWrapper(<Archeology />)}/>
            <Route path="/region" element={wrapWithWrapper(<Region />)}/>
            <Route path="/war" element={wrapWithWrapper(<War />)}/>
            <Route path="/nature" element={wrapWithWrapper(<Nature />)}/>
            <Route path="/ussr" element={wrapWithWrapper(<Ussr />)}/>
            <Route path="/culture" element={wrapWithWrapper(<Culture />)}/>
            <Route path="/merchants" element={wrapWithWrapper(<Merchants />)}/>
        </Routes>
    </BrowserRouter>
}