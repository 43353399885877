import st from "./AppWrap.module.css";


export const AppWrap = ({children}) => {
    return <div className={st.mainDiv}>
        <div className={st.leftSide}></div>
        <div className={st.rightSide}>
            {children}
        </div>

    </div>
}