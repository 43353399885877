import st from './Archeology.module.css'
import {useEffect, useState} from "react";
import {useLocalStorage} from "../../../useLocalStorage";
import {useNavigate} from "react-router-dom";
import {ImageSwitcher} from "../ImageSwitcher/ImageSwitcher";

const STAGE_1 = 1 // Первая подсказка
const STAGE_2 = 2 // Поиск предметов
const STAGE_3 = 3 // Вторая подсказка
const STAGE_4 = 4 // Поиск лишних предметов

const PERSON_A = 1
const PERSON_B = 2

const HIDE_TIME = 40000
const HINT_TIME = 3000

export const Archeology = ({ playAudio, stopAudio }) => {
    const navigate = useNavigate();
    const [stage, setStage] = useState(STAGE_1) // Состояние сцены
    const [fun, setFun] = useState(false) // Весёлый персонаж
    const [rule, setRule] = useState(false) // Правила поиска
    const [rule2, setRule2] = useState(false) // Правила исключения
    const [corrects, setCorrects] = useState([2, 5, 10, 14]) // Верные индексы поиска
    const [repletion, setRepletion] = useState([7]) // Верные индексы лишних предметов
    const [showPerson, setShowPerson] = useState(false) // Показать игрока
    const [showGuide, setShowGuide] = useState(false) // Показать гида
    const [showSearch, setShowSearch] = useState(false) // Показать доску с названиями
    const [availableHint, setAvailableHint] = useState(true) // Доступна ли лупа
    const [hint, setHint] = useState(null) // Подсказка на индексе предмета
    const [timer, setTimer] = useState(0) // Значение таймера
    const [user] = useLocalStorage("user", null) // Персонаж из настроек

    // Если нет персонажа в настройках, то переходим туда
    if (user === null) navigate('/settings')

    // Игроки, будем потом брать того который в настройках
    const personas = [
        process.env.PUBLIC_URL + '/game/archeology/male.png',
        process.env.PUBLIC_URL + '/game/archeology/female.png',
        process.env.PUBLIC_URL + '/game/archeology/fun_male.png',
        process.env.PUBLIC_URL + '/game/archeology/fun_female.png'
    ]

    // Элементы для поиска и исключения с координатами
    const elements = [
        {w: 9, h: 20, t: 10, l: 8},
        {w: 3, h: 12, t: 23, l: 19},
        {w: 5, h: 6, t: 50, l: 18}, // index 2 important
        {w: 5, h: 11, t: 44, l: 23},
        {w: 19, h: 4, t: 16, l: 26},
        {w: 6, h: 9, t: 22, l: 27}, // index 5 important
        {w: 10, h: 9, t: 22, l: 34},
        {w: 10, h: 17, t: 10, l: 49}, // index 7 negative important
        {w: 10, h: 17, t: 10, l: 60},
        {w: 7, h: 16, t: 39, l: 52},
        {w: 9, h: 16, t: 39, l: 59}, // index 10 important
        {w: 16, h: 17, t: 55, l: 52},
        {w: 12, h: 41, t: 28, l: 77},
        {w: 3, h: 11, t: 28, l: 74},
        {w: 3, h: 14, t: 41, l: 74}, // index 14 important
        {w: 3, h: 15, t: 54, l: 74}
    ]

    // Заголовки на доске для поиска которые потом нужно скрыть
    const searchItemsCoords = [
        {w: 14, h: 11, t: 31, l: 12, ix: 14},
        {w: 32, h: 14, t: 31, l: 32, ix: 10},
        {w: 19, h: 14, t: 51, l: 13, ix: 5},
        {w: 29, h: 17, t: 51, l: 38, ix: 2}
    ]

    // Заголовки на доске для поиска лишнего предмета
    const searchRepletionCoords = [
        {w: 22, h: 11, t: 42, l: 12, ix: null},
        {w: 15, h: 14, t: 42, l: 41, ix: 7},
        {w: 10, h: 14, t: 42, l: 62, ix: null}
    ]

    const itemsToDrop = [
        {w: 5, h: 6, t: 49, l: 18, ix: 2},
        {w: 6, h: 9, t: 22, l: 27, ix: 5},
        {w: 10, h: 17, t: 10, l: 49, ix: 7},
        {w: 9, h: 16, t: 39, l: 59, ix: 10},
        {w: 3, h: 14, t: 39, l: 74, ix: 14}
    ]

    // Показать подсказку
    const showHint = () => {
        if (!availableHint)
            return
        setTimer(HIDE_TIME / 1000)
        if (availableHint) {
            let toHint = null
            if (stage === STAGE_2)
                toHint = corrects[Math.floor(Math.random() * corrects.length)]
            else if (stage === STAGE_4)
                toHint = repletion[Math.floor(Math.random() * repletion.length)]
            else return

            setHint(toHint)
            setAvailableHint(false)
            setTimeout(() => {
                setHint(null)
            }, HINT_TIME)
            playAudio('timer', true)
        }
    }

    // Поиск по предметам
    const searchClick = value => {
        if (stage === STAGE_2)
            if (corrects.includes(value)) {
                const ind = corrects.indexOf(value);
                if (ind > -1) {
                    playAudio('item')
                    setCorrects(corrects.filter(item => item !== value))
                }
            } else {
                console.log('incorrect', value)
            }
        else if (stage === STAGE_4)
            if (repletion.includes(value)) {
                playAudio('item')
                stopAudio('timer')
                navigate('/task2')
            } else {
                console.log('incorrect repletion', value)
            }
    }

    // init
    useEffect(() => {
        setFun(false)
        setShowSearch(false)
        setShowPerson(false)
        setShowGuide(false)
        setTimeout(() => setRule(true), 400)
        setTimeout(() => setShowPerson(true), 100)
        playAudio('background', true)
    }, [])

    useEffect(() => {
        if (corrects.length === 0)
            setStage(STAGE_3)
    }, [corrects])

    useEffect(() => {
        if (timer > 0) {
            if (availableHint)
                setAvailableHint(false)
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        } else {
            stopAudio('timer')
            setAvailableHint(true)
        }
    }, [timer])

    useEffect(() => {
        switch (stage) {
            case STAGE_2:
                setRule(false)
                setShowPerson(false)
                setShowSearch(true)
                break
            case STAGE_3:
                setShowSearch(false)
                setShowGuide(true)
                setRule2(true)
                break
            case STAGE_4:
                setShowGuide(false)
                setShowSearch(true)
                setRule2(false)
                break
        }
    }, [stage])


    return <div className={st.wrapper}>

        {elements.map((el, ix) => {
            let toDrop = itemsToDrop.find(el => el.ix === ix);
            let opacity = toDrop === undefined || (corrects.includes(ix) || repletion.includes(ix)) ? 1 : 0;
            return (
                <div
                    key={`el-${ix}`}
                    onClick={() => searchClick(ix)}
                    className={`${st.element} ${ix === hint ? st.glow : ''}`}
                    style={{
                        width: `${el.w}%`,
                        height: `${el.h}%`,
                        top: `${el.t}%`,
                        left: `${el.l}%`,
                        opacity: opacity ? 1 : 0,
                        backgroundImage: `url(${process.env.PUBLIC_URL}/game/archeology/el${ix}.png)`,
                        '--glow-image': `url(${process.env.PUBLIC_URL}/game/archeology/el${ix}.png)`
                    }}
                ></div>
            );
        })}

        <div className={`${st.rules} ${rule ? st.showRule : st.hideRule}`}>
            <div
                className={st.start}
                onClick={() => {
                    setFun(true)
                    setTimeout(() => {
                        setStage(STAGE_2)
                    }, 1500)
                }}
            ></div>
        </div>

        <div className={`${st.rules2} ${rule2 ? st.showRule2 : st.hideRule2}`}>
            <div
                className={st.close}
                onClick={() => setStage(STAGE_4)}
            ></div>
        </div>

        <ImageSwitcher user={user} fun={fun} personas={personas} showPerson={showPerson} />

        <div className={`${st.guide} ${showGuide ? st.showGuide : st.hideGuide}`}></div>
        <div
            className={`${st.search} ${stage === STAGE_2 ? st.se : st.re} ${showSearch ? st.showSearch : st.hideSearch}`}>
            {stage === STAGE_2 ? (<>
                {searchItemsCoords.map((si, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/archeology/item${ix}.png)`,
                            width: `${si.w}%`,
                            height: `${si.h}%`,
                            top: `${si.t}%`,
                            left: `${si.l}%`,
                            opacity: `${corrects.includes(si.ix) ? 1 : 0.4}`
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>) : (<>
                {searchRepletionCoords.map((sr, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/archeology/repletion${ix}.png)`,
                            width: `${sr.w}%`,
                            height: `${sr.h}%`,
                            top: `${sr.t}%`,
                            left: `${sr.l}%`,
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>)}
            <div
                className={st.glass}
                style={{
                    opacity: `${availableHint ? 1 : 0.6}`,
                    cursor: `${availableHint ? 'pointer' : 'default'}`
                }}
                onClick={showHint}
            >
            </div>
            {!availableHint && (<div className={st.timer}>
                {timer}
            </div>)}
        </div>
    </div>
}