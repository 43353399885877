import st from './Main.module.css'
import {Button} from "@mui/material";

export const Main = () => {
    return <div>
        <p className={st.subtitle}>Катайский краеведческий музей</p>
        <p className={st.subtitle}>Муниципальное учреждение культуры «Катайский краеведческий музей»</p>
        <h1 className={st.title}>Катайский краеведческий музей</h1>
        <p className={st.subtitle}>
            В Катайском краеведческом музее, расположенном в старинном купеческом доме XIX века,
            экспонаты здесь самые разнообразные: средневековые женские украшения,
            старинные предметы быта казаков и крестьян, первобытные орудия труда,
            останки мамонта и зубробизона, а также клад самого купца
        </p>
        <div className={st.buttonsWrapper}>
            <Button
                variant="contained"
                className={st.buttonGreen}
                style={{marginRight: '2%'}}
                href={'/categories'}
            >О проекте</Button>

            <Button
                variant="contained"
                className={st.buttonGreen}
                href={'/start'}
            >Игра</Button>
        </div>

    </div>
};
