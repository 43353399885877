import st from './Start.module.css'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const Start = () => {
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        // setTimeout(() => {
        //     navigate("/game/start")
        // }, 5000)
        const inter = setInterval(() => {
            setProgress((old) => {
                if (old < 100)
                    return old + Math.floor(Math.random() * (5 - 1) + 1);
                else {
                    clearInterval(inter);
                    return 100;
                }
            })
        }, 60)
        return () => {
            clearInterval(inter);
        }
    }, [])
    useEffect(() => {
        if (progress >= 100) {
            navigate("/settings");
        }
    }, [progress])

    return <div className={st.wrapper}>
        <div className={st.title}></div>
        <div className={st.text}></div>
        <div className={st.progressWrapper}>
            <div className={st.progressLine} style={{width: `${100 - progress}%`}}>
                <div className={st.progressCircle} style={{transform: `translate(-${progress / 2}%, -2pt)`}}>
                    <div className={st.progressValue}>
                        <span>{progress}</span>%
                    </div>
                </div>
            </div>
        </div>
    </div>
}