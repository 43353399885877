import st from './Categories.module.css'
import {Button, CircularProgress, List, ListItem} from "@mui/material";
import {useEffect, useState} from "react";
import axios from 'axios';
import {useNavigate} from "react-router-dom";

export const Categories = () => {
    const [cats, setCats] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const fetchCategories = async () => {
        try {
            setLoading(true)
            // Выполняем POST запрос к API
            const response = await axios.get(`http://api.test/categories/index`);

            // Обновляем состояние cats с данными, полученными из API
            setCats(response.data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Ошибка при загрузке категорий:', error);
        }
    };

    useEffect(() => {
        // Выполняем POST запрос при загрузке компонента
        fetchCategories().then(r => console.log(r));
    }, []);

    return <div className={st.wrapper}>
        <div className={st.title}>
            <div>Список наших разделов</div>
            <Button className={st.back}
                    variant="contained"
                    color="error"
                    href={'/'}
            >
                Назад
            </Button>
        </div>

        {loading ? (
            <div className={st.load}>
                <CircularProgress size={100} color="success"/>
            </div>
        ) : (<>
                {cats.length !== 0 ? (
                    <List
                        className={st.list}
                        sx={{width: '100%', bgcolor: 'background.paper'}}>
                        {cats.map((cat, ix) => (
                            <ListItem
                                className={st.listItem}
                                key={ix}
                                disableGutters
                            >
                                <Button
                                    color="success"
                                    className={st.button}
                                    size="large"
                                    variant="outlined"
                                    onClick={() => navigate(`/category/${cat.id}`)}
                                >
                                    {cat.name}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                ) : (<div className={st.noCategories}>
                    <div>Нет доступных категорий</div>
                    <Button
                        className={st.back}
                        variant="contained"
                        color="error"
                        onClick={() => fetchCategories()}
                    >
                        Обновить
                    </Button>
                </div>)}
            </>

        )}
    </div>
}