import React, {useState, useRef, useEffect} from 'react';
import st from "./Wrapper.module.css";
import {useNavigate} from "react-router-dom";


export const Wrapper = ({children}) => {
    const [clicks, setClicks] = useState([]);
    const navigate = useNavigate();

    const audioRefs = {
        background: useRef(new Audio(process.env.PUBLIC_URL + '/game/audios/background.mp3')),
        item: useRef(new Audio(process.env.PUBLIC_URL + '/game/audios/item.mp3')),
        openhall: useRef(new Audio(process.env.PUBLIC_URL + '/game/audios/openhall.mp3')),
        timer: useRef(new Audio(process.env.PUBLIC_URL + '/game/audios/timer.mp3')),
        present: useRef(new Audio(process.env.PUBLIC_URL + '/game/audios/present.mp3')),
    };

    const handleClick = (e) => {
        const newClick = {
            id: Date.now(),
            x: e.clientX + window.scrollX,
            y: e.clientY + window.scrollY,
        };
        setClicks((prevClicks) => [...prevClicks, newClick]);
        setTimeout(() => {
            setClicks((prevClicks) => prevClicks.filter(click => click.id !== newClick.id));
        }, 400);
    };

    const playAudio = (audioKey, loop = false) => {
        const audioRef = audioRefs[audioKey];
        if (audioRef) {
            audioRef.current.loop = loop;
            audioRef.current.volume = loop ? 0.3 : 1;
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            if (audioRef.current.paused)
                audioRef.current.play().catch((error) => {
                    console.error("Error playing audio:", error);
                });
        }
    };

    const stopAudio = (audioKey) => {
        const audioRef = audioRefs[audioKey];
        if (audioRef) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const stopAllAudio = () => {
        Object.values(audioRefs).forEach(audioRef => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        });
    };

    useEffect(() => {
        return () => {
            stopAllAudio();
        };
    }, []);

    return (
        <div className={st.wrapper} onClick={handleClick}>
            {React.cloneElement(children, { playAudio, stopAudio })}

            <div
                className={st.home}
                onClick={() => navigate('/')}
            >
            </div>

            {clicks.map((click) => (
                <div
                    key={click.id}
                    className="click-effect"
                    style={{
                        width: '40px',
                        height: '40px',
                        left: `${click.x - 20}px`,
                        top: `${click.y - 20}px`,
                    }}
                />
            ))}
        </div>
    );
};