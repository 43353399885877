import React, { useState, useEffect } from 'react';
import st from './ImageSwitcher.module.css'

export const ImageSwitcher = ({ user, fun, personas, showPerson }) => {
    const [currentImage, setCurrentImage] = useState(personas[user - 1]);
    const [nextImage, setNextImage] = useState(null);
    const PERSON_A = 1; // Ваше условие

    useEffect(() => {
        const newImageUrl = fun ? personas[2 + user - 1] : personas[user - 1];
        if (newImageUrl !== currentImage) {
            setNextImage(newImageUrl);
        }
    }, [user, fun, personas]);

    const handleTransitionEnd = () => {
        if (nextImage) {
            setCurrentImage(nextImage);
            setNextImage(null);
        }
    };
    /*

        <div
            className={`${st.person} ${showPerson ? st.showPerson : st.hidePerson}`}
            style={{
                backgroundImage: `url(${fun ? personas[2 + user - 1] : personas[user - 1]})`,
                backgroundPositionX: `${user === PERSON_A ? 'center' : 'left'}`
            }}
        ></div>
     */

    return (
        <div className={`${st.personContainer} ${showPerson ? st.showPerson : st.hidePerson}`}>
            <div
                className={st.currentImage}
                style={{
                    backgroundImage: `url(${currentImage})`,
                    backgroundPositionX: `${user === PERSON_A ? 'center' : 'left'}`,
                }}
            />
            {nextImage && (
                <div
                    className={st.nextImage}
                    style={{
                        backgroundImage: `url(${nextImage})`,
                        backgroundPositionX: `${user === PERSON_A ? 'center' : 'left'}`,
                    }}
                    onTransitionEnd={handleTransitionEnd}
                />
            )}
        </div>
    );
};