import {Box, IconButton} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useEffect, useRef, useState} from "react";
import st from './HorizontalScroll.module.css'
import {FullImage} from "../FullImage/FullImage";

export const HorizontalScroll = ({images, title}) => {
    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [isClick, setIsClick] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const [disableLeft, setDisableLeft] = useState(true);
    const [disableRight, setDisableRight] = useState(false);

    useEffect(() => {
        console.log('useEffect')
        if (scrollRef.current) {
            const { scrollWidth, clientWidth } = scrollRef.current;
            console.log('useEffect', scrollWidth, clientWidth)
            setIsScrollable(scrollWidth > clientWidth);
            checkButtons();
        }
    }, []);

    const scrollLeftButton = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: -150, // Ширина одного изображения
                behavior: 'smooth',
            });
        }
    };

    const scrollRightButton = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: 150, // Ширина одного изображения
                behavior: 'smooth',
            });
        }
    };

    const onMouseDown = (e) => {
        setIsDragging(true);
        setIsClick(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const onMouseLeaveOrUp = () => {
        setIsDragging(false);
    };

    const onMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        setIsClick(false); // Устанавливаем, что это был не просто клик
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 1; // Множитель прокрутки
        scrollRef.current.scrollLeft = scrollLeft - walk;
        checkButtons();
    };

    const onClickImage = (src) => {
        if (isClick) {
            setSelectedImage(src);
            setOpen(true);
        }
    };

    const checkButtons = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setDisableLeft(scrollLeft <= 0);
            setDisableRight(scrollLeft + clientWidth >= scrollWidth);
            console.log("checkButtons called: ", scrollLeft, scrollWidth, clientWidth);
        }
    };

    return (
        <>
            <Box>
                <div className={st.topBox}>
                    <div className={st.scrollTitle}>{title}</div>
                    {isScrollable && (
                        <Box className={st.arrowsBox}>
                            <IconButton
                                className={st.scrollButton}
                                aria-label="Сдвинуть влево"
                                onClick={scrollLeftButton}
                                disabled={disableLeft}
                                style={{backgroundColor: `${disableLeft ? '#2F71D580' : '#2f71d4'}`, marginRight: '20pt'}}
                                variant="contained"
                            >
                                <ArrowBackIcon
                                    className={st.scrollButtonIcon}
                                />
                            </IconButton>
                            <IconButton
                                className={st.scrollButton}
                                aria-label="Сдвинуть вправо"
                                onClick={scrollRightButton}
                                disabled={disableRight}
                                style={{backgroundColor: `${disableRight ? '#2F71D580' : '#2f71d4'}`}}
                                variant="contained"
                                ml={2}
                            >
                                <ArrowForwardIcon
                                    className={st.scrollButtonIcon}
                                />
                            </IconButton>
                        </Box>
                    )}
                </div>

                <Box
                    className={st.imagesBox}
                    ref={scrollRef}
                    onMouseDown={onMouseDown}
                    onMouseLeave={onMouseLeaveOrUp}
                    onMouseUp={onMouseLeaveOrUp}
                    onMouseMove={onMouseMove}
                    onScroll={checkButtons}
                    sx={{
                        '&::-webkit-scrollbar': {display: 'none'}, // Убираем скроллбар
                        cursor: isDragging ? 'grabbing' : 'grab',
                        userSelect: 'none',
                    }}
                >
                    {images.map((src, index) => (
                        <Box
                            onClick={() => onClickImage(src)}
                            className={st.imageBox}
                            key={index}
                            mx={1}
                        >
                            <FullImage image={src} open={open} selectedImage={selectedImage}
                                       setOpen={val => setOpen(val)}/>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    );
};