
import st from './Task6.module.css'
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const STAGE_1 = 1 // Правила
const STAGE_2 = 2 // Верно
const STAGE_3 = 3 // Не верно
const CORRECT = 'культура'

export const Task6 = ({ playAudio }) => {

    const navigate = useNavigate();
    const [stage, setStage] = useState(STAGE_1);
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    const handleInput = e => {
        let value = e.target.value
        if (value.length === 0)
            setStage(STAGE_1)
        else {
            if (value.toLowerCase() === CORRECT) {
                setIsFocused(false)
                setStage(STAGE_2)
            } else
                setStage(STAGE_3)
        }
    }

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus(); // Устанавливаем фокус на поле ввода
        } else if (!isFocused && inputRef.current) {
            inputRef.current.blur(); // Убираем фокус с поля ввода
        }
    }, [isFocused]);

    useEffect(() => {
        playAudio('background', true)
    }, []);

    return <div className={st.wrapper}>
        <div className={`${st.guide} ${stage === STAGE_2 ? st.guideFun : st.guideStand}`}></div>

        <div className={st.blank}>
            <div className={st.description} onClick={() => setIsFocused(true)}>
                <input
                    ref={inputRef}
                    className={st.answer}
                    onChange={handleInput}
                />
                {stage === STAGE_2 ? (
                    <div>
                        <CheckCircleIcon
                            className={st.circleIcon}
                            style={{color: '#21B214'}}
                        />
                        <div className={st.correct}></div>
                        <div
                            className={st.hall}
                            onClick={() => {
                                playAudio('openhall')
                                navigate('/culture')
                            }}
                        ></div>
                    </div>
                ) : (<></>)}
                {stage === STAGE_3 ? (
                    <div>
                        <CancelIcon
                            className={st.circleIcon}
                            style={{color: '#FF0208'}}
                        />
                        <div className={st.incorrect}></div>
                    </div>
                ) : (<></>)}
            </div>
        </div>
    </div>
}