import st from './Nature.module.css'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const STAGE_2 = 2 // Поиск предметов
const STAGE_3 = 3 // Подсказка
const STAGE_4 = 4 // Поиск лишних предметов

const HIDE_TIME = 40000
const HINT_TIME = 3000

export const Nature = ({ playAudio, stopAudio }) => {
    const navigate = useNavigate();
    const [stage, setStage] = useState(STAGE_2) // Состояние сцены
    const [rule2, setRule2] = useState(false) // Правила исключения
    const [corrects, setCorrects] = useState([0, 1, 2, 3]); // Верные индексы поиска
    const [repletion, setRepletion] = useState([4]); // Верные индексы лишних предметов
    const [showGuide, setShowGuide] = useState(false) // Показать гида
    const [showSearch, setShowSearch] = useState(false) // Показать доску с названиями
    const [availableHint, setAvailableHint] = useState(true) // Доступна ли лупа
    const [hint, setHint] = useState(null) // Подсказка на индексе предмета
    const [timer, setTimer] = useState(0) // Значение таймера

    // Элементы для поиска и исключения с координатами
    const elements = [
        {w: 7, h: 13, t: 65, l: 11},
        {w: 4, h: 12, t: 10, l: 11.5},
        {w: 6, h: 8, t: 61, l: 47},
        {w: 3, h: 8, t: 53, l: 66},
        {w: 10, h: 17, t: 12, l: 83},
    ]


    // Заголовки на доске для поиска которые потом нужно скрыть
    const searchItemsCoords = [
        {w: 15, h: 13, t: 32, l: 11, ix: 0},
        {w: 15, h: 12, t: 32, l: 33, ix: 2},
        {w: 14, h: 13, t: 32, l: 54, ix: 3},
        {w: 22, h: 10, t: 54, l: 32, ix: 1}
    ]

    // Заголовки на доске для поиска лишнего предмета
    const searchRepletionCoords = [
        {w: 14, h: 11, t: 32, l: 20, ix: null},
        {w: 19, h: 14, t: 32, l: 39, ix: null},
        {w: 48, h: 11, t: 54, l: 18, ix: 4}
    ]

    const itemsToDrop = [
        {ix: 0},
        {ix: 1},
        {ix: 2},
        {ix: 3},
        {ix: 4}
    ]

    // Показать подсказку
    const showHint = () => {
        if (!availableHint)
            return
        setTimer(HIDE_TIME / 1000)
        if (availableHint) {
            let toHint = null
            if (stage === STAGE_2)
                toHint = corrects[Math.floor(Math.random() * corrects.length)]
            else if (stage === STAGE_4)
                toHint = repletion[Math.floor(Math.random() * repletion.length)]
            else return

            setHint(toHint)
            setAvailableHint(false)
            setTimeout(() => {
                setHint(null)
            }, HINT_TIME)
            playAudio('timer', true)
        }
    }

    // Поиск по предметам
    const searchClick = value => {
        console.log(value)
        if (stage === STAGE_2)
            if (corrects.includes(value)) {
                const ind = corrects.indexOf(value);
                if (ind > -1) {
                    playAudio('item')
                    setCorrects(corrects.filter(item => item !== value))
                }
            } else {
                console.log('incorrect', value)
            }
        else if (stage === STAGE_4)
            if (repletion.includes(value)) {
                playAudio('item')
                stopAudio('timer')
                navigate('/task5')
            } else {
                console.log('incorrect repletion', value)
            }
    }

    // init
    useEffect(() => {
        setShowSearch(true)
        setShowGuide(false)
        setStage(STAGE_2)
        playAudio('background', true)
    }, [])

    useEffect(() => {
        if (corrects.length === 0)
            setStage(STAGE_3)
    }, [corrects])

    useEffect(() => {
        if (timer > 0) {
            if (availableHint)
                setAvailableHint(false)
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        } else {
            stopAudio('timer')
            setAvailableHint(true)
        }
    }, [timer])

    useEffect(() => {
        switch (stage) {
            case STAGE_3:
                setShowSearch(false)
                setShowGuide(true)
                setRule2(true)
                break
            case STAGE_4:
                setShowGuide(false)
                setShowSearch(true)
                setRule2(false)
                break
        }
    }, [stage])

    return <div className={st.wrapper}>

        {elements.map((el, ix) => {
                let toDrop = itemsToDrop.find(el => el.ix === ix)
                let opacity = toDrop === undefined || (corrects.includes(ix) || repletion.includes(ix)) ? 1 : 0
                return <div
                    key={`el-${ix}`}
                    onClick={() => searchClick(ix)}
                    className={`${st.element} ${ix === hint ? st.glow : ''}`}
                    style={{
                        width: `${el.w}%`,
                        height: `${el.h}%`,
                        top: `${el.t}%`,
                        left: `${el.l}%`,
                        opacity: opacity ? 1 : 0,
                        backgroundImage: `url(${process.env.PUBLIC_URL}/game/nature/el${ix}.png)`,
                        '--glow-image': `url(${process.env.PUBLIC_URL}/game/nature/el${ix}.png)`
                    }}
                ></div>
            }
        )}

        <div className={`${st.rules2} ${rule2 ? st.showRule2 : st.hideRule2}`}>
            <div
                className={st.close}
                onClick={() => setStage(STAGE_4)}
            ></div>
        </div>
        <div className={`${st.guide} ${showGuide ? st.showGuide : st.hideGuide}`}></div>
        <div
            className={`${st.search} ${stage === STAGE_2 ? st.se : st.re} ${showSearch ? st.showSearch : st.hideSearch}`}>
            {stage === STAGE_2 ? (<>
                {searchItemsCoords.map((si, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/nature/item${ix}.png)`,
                            width: `${si.w}%`,
                            height: `${si.h}%`,
                            top: `${si.t}%`,
                            left: `${si.l}%`,
                            opacity: `${corrects.includes(si.ix) ? 1 : 0.4}`
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>) : (<>
                {searchRepletionCoords.map((sr, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/nature/repletion${ix}.png)`,
                            width: `${sr.w}%`,
                            height: `${sr.h}%`,
                            top: `${sr.t}%`,
                            left: `${sr.l}%`,
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>)}
            <div
                className={st.glass}
                style={{
                    opacity: `${availableHint ? 1 : 0.6}`,
                    cursor: `${availableHint ? 'pointer' : 'default'}`
                }}
                onClick={showHint}
            ></div>
            {!availableHint && (<div className={st.timer}>
                {timer}
            </div>)}
        </div>
    </div>
}