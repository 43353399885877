import st from './Category.module.css'
import {styled, alpha} from '@mui/material/styles';
import {useNavigate, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InputBase from '@mui/material/InputBase';
import {Button, CircularProgress, Grid, IconButton} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {Post} from "../Post/Post";


const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0'
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));


export const Category = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState(null)
    const [posts, setPosts] = useState([])
    const [search, setSearch] = useState("")
    const inputRef = useRef(null);

    const fetchCategory = async () => {
        try {
            setLoading(true)
            // Выполняем POST запрос к API
            const response = await axios.get(`http://api.test/categories/category/${params.id}`);

            // Обновляем состояние cats с данными, полученными из API
            setCategory(response.data);
            setPosts(response.data.posts)
            setLoading(false)
        } catch (error) {
            setCategory(null)
            setPosts([])
            setLoading(false)
            console.error('Ошибка при загрузке категорий:', error);
        }
    };

    useEffect(() => {

        if (category === null)
            return
        if (search === "")
            setPosts(category.posts || [])
        else {
            const lowercasedSearch = search.toLowerCase()
            const filteredPosts = category.posts.filter(post =>
                post.name.toLowerCase().includes(lowercasedSearch)
            )
            setPosts(filteredPosts)
        }


    }, [search])

    useEffect(() => {
        if (inputRef.current)
            inputRef.current.focus()
    }, [inputRef])

    useEffect(() => {
        // Выполняем POST запрос при загрузке компонента
        fetchCategory().then(r => console.log(r))
    }, [])

    const handleSearchChange = (event) => {
        const inputValue = event.target.value
        setSearch(inputValue)
    };
    return <div className={st.wrapper}>
        {category !== null && !loading ? (
            <div className={st.elementsWrapper}>
                <div className={st.topLine}>
                    <div className={st.back}>
                        <IconButton
                            className={st.iconButton}
                            color="error"
                            aria-label="Выйти назад"
                            onClick={() => setTimeout(() => {
                                navigate('/categories')
                            }, 450)}
                        >
                            <KeyboardBackspaceIcon
                                className={st.icon}
                                sx={{fontSize: 50}}
                            />
                        </IconButton>
                    </div>
                    <div className={st.title}>
                        {category.name}
                    </div>
                    <div className={st.search}>
                        <Search className={st.searchElement}>
                            <SearchIconWrapper>
                                <SearchIcon color="error"/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                inputRef={inputRef}
                                placeholder="Найти"
                                inputProps={{'aria-label': 'search'}}
                                onChange={handleSearchChange}
                                value={search}
                            />
                        </Search>
                    </div>
                </div>
                <div className={st.content}>
                    <div className={st.description}>
                        {category.description}
                    </div>
                    {posts?.length === 0 ? (<div>Нет статей</div>) : (
                        <div className={st.postsScroll}>
                            <Grid container spacing={2}>
                                {posts.map((post, ix) => (
                                    <Grid
                                        key={`p-${post.id}`}
                                        className={`${st.grid} ${st['grid-enter']}`}
                                        item
                                        xs={3}
                                    >
                                        <Post postData={post}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <div className={st.load}>
                <CircularProgress size={100} color="success"/>
            </div>
        )}
    </div>
}