import st from './Merchants.module.css'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const STAGE_2 = 2 // Поиск предметов
const STAGE_3 = 3 // Подсказка
const STAGE_4 = 4 // Поиск лишних предметов
const STAGE_5 = 5 // Финиш

const CH_1 = 1 // Появление сундука
const CH_2 = 2 // Перемещение, увеличение
const CH_3 = 3 // Открытие без золота
const CH_4 = 4 // Желтое золото
const CH_5 = 5 // Красное золото

const HIDE_TIME = 40000
const HINT_TIME = 3000
const ANIM = 600

export const Merchants = ({ playAudio, stopAudio }) => {
    const navigate = useNavigate();
    const [stage, setStage] = useState(STAGE_2) // Состояние сцены
    const [chest, setChest] = useState(CH_1) // Состояние сундука
    const [rule2, setRule2] = useState(false) // Правила исключения
    const [corrects, setCorrects] = useState([0, 1, 2, 3]); // Верные индексы поиска
    const [repletion, setRepletion] = useState([4]); // Верные индексы лишних предметов
    const [showGuide, setShowGuide] = useState(false) // Показать гида
    const [showSearch, setShowSearch] = useState(false) // Показать доску с названиями
    const [availableHint, setAvailableHint] = useState(true) // Доступна ли лупа
    const [hint, setHint] = useState(null) // Подсказка на индексе предмета
    const [timer, setTimer] = useState(0) // Значение таймера


    // Элементы для поиска и исключения с координатами
    const elements = [
        {w: 5, h: 6, t: 59, l: 19.5},
        {w: 3, h: 8, t: 34.4, l: 2.4},
        {w: 4, h: 11, t: 39.6, l: 57},
        {w: 7, h: 18, t: 24.4, l: 92},
        {w: 11, h: 13, t: 53, l: 1.6}
    ]


    // Заголовки на доске для поиска которые потом нужно скрыть
    const searchItemsCoords = [
        {w: 11, h: 15, t: 32, l: 12, ix: 0},
        {w: 19, h: 13, t: 32, l: 30, ix: 1},
        {w: 10, h: 14, t: 32, l: 56, ix: 2},
        {w: 17, h: 14, t: 54, l: 32, ix: 3},
    ]

    // Заголовки на доске для поиска лишнего предмета
    const searchRepletionCoords = [
        {w: 8, h: 14, t: 32, l: 24, ix: null},
        {w: 13, h: 11, t: 32, l: 40, ix: null},
        {w: 41, h: 13, t: 52, l: 20, ix: 4}
    ]

    const itemsToDrop = [
        {ix: 0},
        {ix: 1},
        {ix: 2},
        {ix: 3},
        {ix: 4}
    ]

    // Показать подсказку
    const showHint = () => {
        if (!availableHint)
            return
        setTimer(HIDE_TIME / 1000)
        if (availableHint) {
            let toHint = null
            if (stage === STAGE_2)
                toHint = corrects[Math.floor(Math.random() * corrects.length)]
            else if (stage === STAGE_4)
                toHint = repletion[Math.floor(Math.random() * repletion.length)]
            else return

            setHint(toHint)
            setAvailableHint(false)
            setTimeout(() => {
                setHint(null)
            }, HINT_TIME)
            playAudio('timer', true)
        }
    }

    // Поиск по предметам
    const searchClick = value => {
        if (stage === STAGE_2)
            if (corrects.includes(value)) {
                const ind = corrects.indexOf(value);
                if (ind > -1) {
                    playAudio('item')
                    setCorrects(corrects.filter(item => item !== value))
                }
            } else {
                console.log('incorrect', value)
            }
        else if (stage === STAGE_4)
            if (repletion.includes(value)) {
                setRepletion(repletion.filter(item => item !== value))
                playAudio('item')
                stopAudio('timer')
                setStage(STAGE_5)
                setTimeout(() => { // Перемещение сундука
                    setChest(CH_2)
                }, 100)
                setTimeout(() => { // Открытие
                    playAudio('present')
                    setChest(CH_3)
                }, 1500)
                setTimeout(() => { // В анимацию
                    setChest(CH_4)
                }, 1900)
            } else {
                console.log('incorrect repletion', value)
            }
    }

    const getChestClass = () => {
        switch (chest) {
            case CH_2:
                return st.chestMove
            case CH_3:
                return st.chestOpen
            case CH_4:
                return st.chestYellow
            case CH_5:
                return st.chestRed
            default:
                return st.chestInit
        }
    }

    // init
    useEffect(() => {
        setShowSearch(true)
        setShowGuide(false)
        setStage(STAGE_2)
        playAudio('background', true)
    }, [])

    useEffect(() => {
        if (corrects.length === 0)
            setStage(STAGE_3)
    }, [corrects])

    useEffect(() => {
        if (timer > 0) {
            if (availableHint)
                setAvailableHint(false)
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        } else {
            stopAudio('timer')
            setAvailableHint(true)
        }
    }, [timer])

    useEffect(() => {
        switch (stage) {
            case STAGE_3:
                setShowSearch(false)
                setShowGuide(true)
                setRule2(true)
                break
            case STAGE_4:
                setShowGuide(false)
                setShowSearch(true)
                setRule2(false)
                break
            case STAGE_5:
                setShowSearch(false)
                setShowGuide(false)
                setRule2(false)
                break
        }
    }, [stage])

    useEffect(() => {
        switch (chest) {
            case CH_4:
                setTimeout(() => {
                    setChest(CH_5)
                }, ANIM)
                break
            case CH_5:
                setTimeout(() => {
                    setChest(CH_4)
                }, ANIM)
                break
        }
    }, [chest]);

    return <div className={st.wrapper}>
        {elements.map((el, ix) => {
                let toDrop = itemsToDrop.find(el => el.ix === ix)
                let opacity = toDrop === undefined || (corrects.includes(ix) || repletion.includes(ix)) ? 1 : 0
                return <div
                    key={`el-${ix}`}
                    onClick={() => searchClick(ix)}
                    className={`${st.element} ${ix === hint ? st.glow : ''}`}
                    style={{
                        width: `${el.w}%`,
                        height: `${el.h}%`,
                        top: `${el.t}%`,
                        left: `${el.l}%`,
                        opacity: opacity ? 1 : 0,
                        backgroundImage: `url(${process.env.PUBLIC_URL}/game/merchants/el${ix}.png)`,
                        '--glow-image': `url(${process.env.PUBLIC_URL}/game/merchants/el${ix}.png)`
                    }}
                ></div>
            }
        )}

        <div className={`${st.rules2} ${rule2 ? st.showRule2 : st.hideRule2}`}>
            <div
                className={st.close}
                onClick={() => setStage(STAGE_4)}
            ></div>
        </div>
        <div className={`${st.guide} ${showGuide ? st.showGuide : st.hideGuide}`}></div>
        <div
            className={`${st.search} ${stage === STAGE_2 ? st.se : st.re} ${showSearch ? st.showSearch : st.hideSearch}`}>
            {stage === STAGE_2 ? (<>
                {searchItemsCoords.map((si, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/merchants/item${ix}.png)`,
                            width: `${si.w}%`,
                            height: `${si.h}%`,
                            top: `${si.t}%`,
                            left: `${si.l}%`,
                            opacity: `${corrects.includes(si.ix) ? 1 : 0.4}`
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>) : (<>
                {searchRepletionCoords.map((sr, ix) => {
                    return <div
                        key={ix}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/game/merchants/repletion${ix}.png)`,
                            width: `${sr.w}%`,
                            height: `${sr.h}%`,
                            top: `${sr.t}%`,
                            left: `${sr.l}%`,
                        }}
                        className={st.searchItem}>
                    </div>
                })}
            </>)}
            <div
                className={st.glass}
                style={{
                    opacity: `${availableHint ? 1 : 0.6}`,
                    cursor: `${availableHint ? 'pointer' : 'default'}`
                }}
                onClick={showHint}
            >
            </div>
            {!availableHint && (<div className={st.timer}>
                {timer}
            </div>)}
        </div>
        {stage === STAGE_5 && (<div className={st.finish}>
                <div className={st.blackboard}>
                    <div
                        style={{left: '37%'}}
                        className={st.button}
                        onClick={() => navigate('/')}
                    ></div>
                    <div
                        style={{left: '52%'}}
                        className={st.button}
                        onClick={() => navigate('/settings')}
                    ></div>
                </div>
                <div className={`${chest === CH_1 || chest === CH_2 ? st.chest : st.chestFast} ${getChestClass()}`}>

                </div>
            </div>
        )
        }
    </div>
}